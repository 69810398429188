import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <main className="page-container">
        <section className="text-container">
          <p className="text-line">Brother Digital</p>
          <p className="text-line">
            is a software company focused on crafting delightful consumer
            products for iOS, Android and other platforms.
          </p>
          <p className="text-line">Founded in 2020.</p>

          <p className="text-line">
             <a href="mailto:ben@brother-digital.com">Reach us by email</a>
          </p>

        </section>
      </main>
    </div>
  );
}

export default App;
